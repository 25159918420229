import { useEffect, useState } from "react";
import Loader from "./globals/elements/loader";
import ScrollToTop from "./globals/scroll-to-top";
import RootLayout from "./layouts/root-layout";

function App() {

 
    // useEffect(() => {
    //   const disableRightClick = (e) => {
    //     e.preventDefault();
    //   };
      
    //   document.addEventListener('contextmenu', disableRightClick);
      
    //   return () => {
    //     document.removeEventListener('contextmenu', disableRightClick);
    //   };
    // }, []);
  

  const [isLoading, setLoading] = useState(true);

  setTimeout(() => {
    setLoading(false);
  }, 2000);

  return (
    <>
      {isLoading && <Loader />}
     
      <RootLayout />
    </>
  );
}

export default App;
