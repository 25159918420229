import React from 'react'
import CareerForm from './CareerForm'
 
export const Career = () => {
  return (
    <>
   <CareerForm/>
    </>
  )
}
