import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";
import OwlCarousel from "react-owl-carousel";
function SectionHome1Testimonials() {
  const options = {
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayTimeout: 2000,
    autoplayHoverPause: false,
    autoplaySpeed: 2000, // Control the speed of sliding
    slideTransition: "linear", // For continuous smooth scrolling
    items: 3, // Number of items to show
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };
  return (
    <>
    <div
      className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
      style={{
        backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
      }}
    >
      <div className="container">
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2 data-title=" ">Our Client</h2>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}

        {/* slider For clients  */}

        {/* <OwlCarousel  className='owl-theme' {...options}>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/3.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
            
            
            </OwlCarousel> */}

        {/* End slider For clients  */}

        <div className="section-content">
          {/* <div className="owl-carousel home-carousel-1" {...options}>
            <div className="item">
              <div className="testimonial-2  clearfix bg-white">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/3.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                  <span className="testimonial-position">
                    General Manager - Law
                  </span>

                  <span className="fa fa-quote-left" />
                  <p>
                    Chemilex Industries stands out for their transparent value
                    delivery and relationship-focused business approach. Their
                    professionalism and reliability have been impressive
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                <div className="testimonial-detail ">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                   
                  <span className="testimonial-position">Purchase Manager</span>

                  <span className="fa fa-quote-left" />
                  <p>
                    We are consistently satisfied with the quality and
                    commitment of Chemilex Industries. Their products and
                    services exceed our expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2  clearfix  bg-white">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                 
                  <span className="testimonial-position">COAL DESK- HEAD</span>

                  <span className="fa fa-quote-left" />
                  <p>
                    Chemilex Industries demonstrates exceptional
                    professionalism. Their adherence to quality and timely
                    service has made them a valued partner.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <OwlCarousel className="owl-theme home-carousel-1" {...options}>
            <div className="item">
              <div className="testimonial-2 clearfix ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Atul_logo.png"
                      width={100}
                      height={100}
                      alt="General Manager"
                    />
                  </div>
                </div>
                
              </div>
            </div>

            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Biltech.jpeg"
                      width={100}
                      height={100}
                      alt="Purchase Manager"
                    />
                  </div>
                </div>
              
              </div>
            </div>

            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Birla_Copper.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/BodalLogo.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Dabour.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Delite_AAC.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Dipak_Phenolics.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Jamipol-Logo.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/kilburnlogo.jpg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Meghmani.jpg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Narmada_Gelatin.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/NMDC.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/nsail-logo.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Orbix.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/RBIL.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Shri_Puskar.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/shubhalakshmi-logo.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Sun_Flag.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix  ">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/Visa_Steel.jpeg"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div>
               
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
      <div
      className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
      style={{
        backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
      }}
    >
      <div className="container">
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2 data-title=" ">Our Reviews</h2>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}

        {/* slider For clients  */}

        {/* <OwlCarousel  className='owl-theme' {...options}>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/3.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                <div className="testimonial-pic">
                <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
            
            
            </OwlCarousel> */}

        {/* End slider For clients  */}

        <div className="section-content">
          {/* <div className="owl-carousel home-carousel-1" {...options}>
            <div className="item">
              <div className="testimonial-2  clearfix bg-white">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/3.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                  <span className="testimonial-position">
                    General Manager - Law
                  </span>

                  <span className="fa fa-quote-left" />
                  <p>
                    Chemilex Industries stands out for their transparent value
                    delivery and relationship-focused business approach. Their
                    professionalism and reliability have been impressive
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                <div className="testimonial-detail ">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                   
                  <span className="testimonial-position">Purchase Manager</span>

                  <span className="fa fa-quote-left" />
                  <p>
                    We are consistently satisfied with the quality and
                    commitment of Chemilex Industries. Their products and
                    services exceed our expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2  clearfix  bg-white">
                <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div>
                </div>
                <div className="testimonial-text">
                 
                  <span className="testimonial-position">COAL DESK- HEAD</span>

                  <span className="fa fa-quote-left" />
                  <p>
                    Chemilex Industries demonstrates exceptional
                    professionalism. Their adherence to quality and timely
                    service has made them a valued partner.
                  </p>
                </div>
              </div>
            </div>
          </div> */}

          <OwlCarousel className="owl-theme home-carousel-1" {...options}>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/3.png"
                      width={100}
                      height={100}
                      alt="General Manager"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">General Manager - Law</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Working with Chemilex has been a game-changer for our operations. Their commitment to quality products and unmatched support ensures that our processes run smoothly and efficiently.
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/5.png"
                      width={100}
                      height={100}
                      alt="Purchase Manager"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">Purchase Manager</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  The dedication Chemilex shows to sustainability and innovation is truly commendable. Their environmentally conscious practices align perfectly with our values, making them a trusted long-term partner.
                  </p>
                </div>
              </div>
            </div>

            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  For years, we’ve relied on Chemilex for industrial minerals to enhance our production processes, and they’ve never let us down. The team is always ready with solutions that exceed expectations.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  The exceptional quality of products from Chemilex consistently surpasses our expectations. Their attention to detail and customer care makes them our top choice for sourcing materials.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  The professionalism and reliability of Chemilex are unparalleled. They consistently deliver on time, ensuring our operations never face delays, making them an integral part of our supply chain.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Chemilex’s contribution to optimizing our production processes has been invaluable. Their commitment to high-quality products and outstanding customer service has greatly enhanced our efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  We deeply value the expertise Chemilex brings to the table. Their ability to provide top-tier products and personalized service sets them apart as a partner we can trust.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Since partnering with Chemilex, our operational efficiency has improved drastically. Their superior products and exceptional service have made them an indispensable asset to our business.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Chemilex consistently meets and exceeds our stringent quality standards. Their professionalism, reliability, and attention to detail make them a pleasure to work with on every project.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  What sets Chemilex apart is their commitment to both excellence and sustainability. Their high-quality products and ethical practices reinforce our decision to continue the partnership.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Our experience with Chemilex has been nothing but outstanding. The materials always meet our high standards, and the service team is consistently reliable, making every interaction smooth.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  The superior quality of Chemilex’s materials has had a noticeable impact on our productivity. Reliable deliveries and top-notch customer support are crucial to our success.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  More than just a supplier, Chemilex is a partner we can rely on for exceptional products and services. Their commitment to detail and sustainability aligns perfectly with our goals.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Chemilex’s dedication to delivering consistent product quality and a customer-first approach truly sets them apart. Their dependability keeps us coming back time and time again.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Working with Chemilex has revolutionized our sourcing process. High-quality products, prompt deliveries, and exceptional service make them stand out as a preferred supplier.

  
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Chemilex consistently delivers both high-quality products and superior service, making them a trusted and reliable partner in our industry. We wouldn’t hesitate to recommend them.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  From our first interaction to the final delivery, the Chemilex team has been professional, dependable, and dedicated to meeting our needs. Their high-quality products are a vital part of our operations.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  The Chemilex team always goes above and beyond to ensure we receive exactly what we need, when we need it. Their product quality and support services make them one of the best suppliers we’ve worked with.
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="testimonial-2 clearfix bg-white">
                {/* <div className="testimonial-detail">
                  <div className="testimonial-pic shadow">
                    <FastImage
                      src="images/testimonials/4.png"
                      width={100}
                      height={100}
                      alt="COAL DESK- HEAD"
                    />
                  </div>
                </div> */}
                <div className="testimonial-text">
                  {/* <span className="testimonial-position">COAL DESK- HEAD</span> */}
                  <span className="fa fa-quote-left" />
                  <p>
                  Chemilex’s unwavering focus on quality, innovation, and sustainability makes them our trusted supplier. We look forward to continuing our partnership and growing together in the future.
                  </p>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </div>
   </>
    
  );
}

export default SectionHome1Testimonials;
